import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import formatWpMenu from "../../utilities/format-wp-menu";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";

const Footer = () => {
  const {
    wp: {
      themeSettings: { themeSettings: data },
    },
    secondaryNav: {
      menuItems: { nodes: secondaryMenu },
    },
    secondaryFooterMenu: {
      menuItems: { nodes: secondaryMenuFooter },
    },
  } = useStaticQuery(graphql`
    query Footer {
      wp {
        themeSettings {
          themeSettings {
            copyright
            awardContent
            awardImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }

      secondaryNav: wpMenu(slug: { eq: "footer-menu" }) {
        menuItems {
          nodes {
            id
            label
            path
            parentId
          }
        }
      }

      secondaryFooterMenu: wpMenu(slug: { eq: "footer-menu-secondary" }) {
        menuItems {
          nodes {
            id
            label
            path
            parentId
          }
        }
      }
    }
  `);

  const secondaryNavMenu = formatWpMenu(secondaryMenu);
  const secondaryNavMenuFooter = formatWpMenu(secondaryMenuFooter);
  const year = new Date().getFullYear();
  data.copyright = data.copyright.replace(/{{ year }}/, year.toString());

  return (
    <>
      <section className="footer-secondary">
        <div className="container">
          <div className="row wrapper">
            <div className="col-sm-4 fs-left">
              {data.awardImage && (
                <CmsImage
                  className="img-fluid mx-auto"
                  image={data.awardImage}
                />
              )}
            </div>
            <div className="col-sm-8 fs-right">
              <Content className="content">{data.awardContent}</Content>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <ul className="nav justify-content-center rowdies text-uppercase mb-2">
            {secondaryNavMenu &&
              secondaryNavMenu.map((link) => (
                <li key={link.id} className="nav-item">
                  <Link className="nav-link" to={link.path}>
                    {link.label}
                  </Link>
                </li>
              ))}
          </ul>
          <div className="footer-locations mb-3 d-none d-lg-block">
            <ul className="nav justify-content-center rowdies text-uppercase">
              {secondaryNavMenuFooter &&
                secondaryNavMenuFooter.map((link) => (
                  <li key={link.id} className="nav-item">
                    <Link className="nav-link" to={link.path}>
                      {link.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          {/* <button className="btn btn-primary d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#footer-locations" aria-expanded="false" aria-controls="collapseExample">
          Locations
        </button> */}
          {/* <div className="collapse d-lg-none" id="footer-locations">
          <ul className="nav flex-column rowdies text-uppercase">
            <li className="nav-item">
              <a className="nav-link">Brainerd</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">East Ridge</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">Elizabethton</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">Hixson</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">Jonesborough</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">People Street</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">W. Market Street</a>
            </li>
          </ul>
        </div> */}

          <div
            className="footer-copy text-center"
            dangerouslySetInnerHTML={{ __html: data.copyright }}
          />
        </div>
      </footer>
    </>
  );
};

export default Footer;
