import React, { createContext, useState } from "react";

const locationDefaultObject = {
  location: "",
  address: "",
  latitude: 0,
  longitude: 0,
};

const SelectedLocationContext = createContext({
  // main
  location: locationDefaultObject,
  setSelectedLocation: (location) => {}, // does not do anything but for autocompletion only
});

export function SelectedLocationContextProvider(props) {
  const [userLocation, setSelectedLocation] = useState(locationDefaultObject);

  function setSelectedLocationHandler(loc) {
    setSelectedLocation((prev) => {
      return loc;
    });
  }

  const context = {
    location: userLocation, // userLocation is from state
    setSelectedLocation: setSelectedLocationHandler, // userLocation is from location
  };

  return (
    <SelectedLocationContext.Provider value={context}>
      {props.children}
    </SelectedLocationContext.Provider>
  );
}

export default SelectedLocationContext;
