import React, { useContext } from "react";
import formatWpMenu from "../../utilities/format-wp-menu";
import { useStaticQuery, graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Link from "../utilities/link";
import SelectedLocationContext from "../../store/selected-location-context";
import CmsLink from "../utilities/cms-link";

const Header = () => {
  const selectedLocationCtx = useContext(SelectedLocationContext);

  const {
    wp: {
      themeSettings: { themeSettings: data },
    },
    primaryNav: {
      menuItems: { nodes: primaryNavLinks },
    },

    wpPage: page,
  } = useStaticQuery(
    graphql`
      query Header {
        wpPage(isFrontPage: { eq: true }) {
          id
          slug
          uri
          databaseId
          title
        }

        wp {
          themeSettings {
            themeSettings {
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
              orderOnlineButton {
                target
                title
                url
              }
              amigoNearMeButton {
                target
                title
                url
              }
            }
          }
        }
        primaryNav: wpMenu(slug: { eq: "primary-menu" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
              databaseId
              childItems {
                nodes {
                  id
                  label
                  url
                  path
                }
              }
            }
          }
        }
      }
    `
  );

  const primaryNav = formatWpMenu(primaryNavLinks);

  const handleToggle = () => {
    // header.current.classList.trigger("offcanvas.close");
    document.querySelector(".close-offcanvas").click();
  };

  return (
    <>
      <header className="header">
        <div className="header-top d-none d-lg-block pt-4">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <Link className="" to="/locations">
                      <button type="button" className="btn btn-secondary">
                        Amigo Near Me
                      </button>
                    </Link>
                  </div>
                  {/*<div className="header-location">
                    <h6 className="mb-0 text-uppercase">
                      {selectedLocationCtx.location.location}
                    </h6>

                    <Content className="mb-0 p-0 location-add">
                      {selectedLocationCtx.location.address}
                    </Content>
                  </div>*/}
                </div>
              </div>

              {data.orderOnlineButton && (
                <div className="col-auto">
                  <CmsLink className="btn btn-primary" link={data.orderOnlineButton} />
                </div>
              )}

            </div>
          </div>
        </div>
        <nav
          className="navbar navbar-expand-lg navbar-light py-0"
          id="primary-menu-nav"
        >
          <div className="container">
            <Link className="navbar-brand d-lg-none" to="/">
              <CmsImage className="img-fluid mx-auto" image={data.logo} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#mobile-nav"
              aria-controls="mobile-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <span className="navbar-toggler-icon"></span> */}

              <svg
                width="38"
                height="27"
                viewBox="0 0 38 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2H36M2 13.5H36M2 25H36"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div className="collapse navbar-collapse" id="main-nav">
              <div className="header-nav">
                <div className="row">
                  <ul className="navbar-nav primary-nav align-items-center justify-content-center px-0">
                    <li></li>
                    {primaryNav &&
                      primaryNav.map((link, i) => (
                        <li
                          key={link.id}
                          className={
                            link.path !== page.uri
                              ? i >= 3
                                ? "nav-link-left"
                                : "nav-link-right"
                              : "nav-item-logo"
                          }
                        >
                          {
                            <>
                              {link.path !== page.uri ? (
                                // <Link
                                //   className="nav-link"
                                //   activeClassName="nav-link-active"
                                //   to={link.path}
                                // >
                                //   {link.label}

                                // </Link>

                                <>
                                  {link.childItems.nodes.length !== 0 ? (
                                    <div className="nav-item dropdown">
                                      <Link
                                        to={link.path}
                                        className="dropdown-toggle dropdown-pos"
                                        data-toggle="dropdown"
                                      >
                                        {link.label}
                                      </Link>
                                      <div className="dropdown-menu primary-dropdown">
                                        {link.childItems.nodes &&
                                          link.childItems.nodes.map(
                                            (childItem, key) => (
                                              <Link
                                                key={key}
                                                to={childItem.path}
                                                className="dropdown-item"
                                                activeClassName="active"
                                              >
                                                {childItem.label}
                                              </Link>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  ) : (
                                    <Link
                                      className="nav-link"
                                      to={link.path}
                                      activeClassName="nav-link-active"
                                    >
                                      {link.label}
                                    </Link>
                                  )}
                                </>
                              ) : (
                                <Link
                                  className="header-center-logo"
                                  to={link.path}
                                >
                                  <CmsImage
                                    className="img-fluid mx-auto"
                                    image={data.logo}
                                  />
                                </Link>
                              )}
                            </>
                          }
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div
        className="mobile-nav offcanvas offcanvas-bottom"
        tabIndex="-1"
        id="mobile-nav"
        aria-labelledby="mobile-nav"
      >
        <div className="offcanvas-body">
          <div className="">
            <ul className="nav flex-column text-center rowdies text-uppercase mb-4 px-1">
              {primaryNav &&
                primaryNav.map((link, index) => (
                  <li key={link.id} className="nav-item">
                    {
                      <>
                        {link.childItems.nodes.length !== 0 ? (
                          <a
                            className="nav-link has-child-menu"
                            data-bs-toggle="collapse"
                            href={`#mobile-nav-locations-` + index}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`mobile-nav-locations-` + index}
                          >
                            {link.label}
                          </a>
                        ) : (
                          <Link
                            className="nav-link"
                            to={link.path}
                            onClick={handleToggle}
                          >
                            {link.label}
                          </Link>
                        )}
                      </>
                    }

                    {link.childItems.nodes.length !== 0 && (
                      <div
                        className="collapse d-lg-none mobile-nav-locations"
                        id={`mobile-nav-locations-` + index}
                      >
                        <ul className="nav flex-column rowdies text-uppercase text-center mb-4">
                          {link.childItems.nodes &&
                            link.childItems.nodes.map((childItem) => (
                              <li
                                onClick={handleToggle}
                                key={childItem.id}
                                className="nav-item"
                              >
                                <Link
                                  to={childItem.path}
                                  className="nav-link"
                                  activeClassName="text-blue-500"
                                >
                                  {childItem.label}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
            </ul>

            {data.orderOnlineButton && (
              <div className="mobile-nav-location text-center">
                <div className="col-auto">
                  <CmsLink className="btn btn-secondary" link={data.orderOnlineButton} onClick={handleToggle} />
                </div>
              </div>
            )}
            {data.amigoNearMeButton && (
              <div className="mobile-nav-location text-center mt-4">
                <div className="col-auto">
                  <CmsLink className="btn btn-secondary" link={data.amigoNearMeButton} onClick={handleToggle} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="offcanvas-header">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <Link onClick={handleToggle} to="/">
                  <CmsImage
                    className="img-fluid mobile-nav-logo"
                    image={data.logo}
                  />
                </Link>
              </div>
              <div className="col-auto text-right">
                <button
                  type="button"
                  className="btn btn-link close-offcanvas"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.585786 0.585786C1.36683 -0.195262 2.63317 -0.195262 3.41421 0.585786L15.5 12.6716L27.5858 0.585786C28.3668 -0.195262 29.6332 -0.195262 30.4142 0.585786C31.1953 1.36683 31.1953 2.63317 30.4142 3.41421L18.3284 15.5L30.4142 27.5858C31.1953 28.3668 31.1953 29.6332 30.4142 30.4142C29.6332 31.1953 28.3668 31.1953 27.5858 30.4142L15.5 18.3284L3.41421 30.4142C2.63317 31.1953 1.36683 31.1953 0.585786 30.4142C-0.195262 29.6332 -0.195262 28.3668 0.585786 27.5858L12.6716 15.5L0.585786 3.41421C-0.195262 2.63317 -0.195262 1.36683 0.585786 0.585786Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
