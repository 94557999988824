import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { SelectedLocationContextProvider } from "../../store/selected-location-context";
import "../../styles/style.scss";
import Footer from "./footer";
import Header from "./header";

export default function Layout({ children }) {
  useEffect(() => {
    import("bootstrap");

    // Does device have touch?
    if ("ontouchstart" in document.documentElement) {
      document.body.classList.add("touch-device");
    }

    AOS.init({
      //disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      disable: function () {
        return window.innerWidth <= 900;
      },
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 200, // offset (in px) from the original trigger point
      delay: 50, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  });

  /*function withMapPopUp() {
    const paths = []; // add path here if you want map pop up wont show example 'locations'
    const url = typeof window !== "undefined" ? window.location.href : "";
    const domain = process.env.GATSBY_URL;
    let with_map = true;

    paths.every((path) => {
      if (`${url}` === `${domain}/${path}/`) {
        with_map = false;
        return false;
      }
      return true;
    });

    return with_map;
  }*/

  return (
    <>
      <SelectedLocationContextProvider>
        <Helmet>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#eb1e2c" />
          <meta name="msapplication-TileColor" content="#eb1e2c" />
          <meta name="theme-color" content="#eb1e2c" />

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Rowdies:wght@300;400;700&family=Ultra&display=swap"
            rel="stylesheet"
          />
          <script type="application/ld+json">
            {`
              {
              "@context": "http://www.schema.org",
              "@type": "Restaurant",
              "name": "Amigo Mexican Restaurant",
              "url": "http://www.amigorestaurantonline.com",
              "logo": "http://www.amigorestaurantonline.com/static/736f510bd383965eacfc8f43b09cadb3/59f31/Amigo-logo.webp",
              "image": "http://www.amigorestaurantonline.com/static/736f510bd383965eacfc8f43b09cadb3/59f31/Amigo-logo.webp",
              "description": "Amigo Mexican Restaurant has award-winning Mexican restaurants in Chattanooga, East Ridge, Johnson City, and Elizabethton with happy hour and enjoys 2-for-1 house margaritas, beer specials, and more! Live your best life– with a drink in hand.",
              "telephone": "423-624-4345",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "East Ridge",
                "addressRegion": "TN",
                "streetAddress": "3805 Ringgold Rd",
                "postalCode": "37412",
                "addressCountry": "United States"
              },
              "openingHours": [
                "Mon 11:00 - 22:00",
                "Tue 11:00 - 22:00",
                "Wed 11:00 - 22:00",
                "Thu 11:00 - 22:00",
                "Fri 11:00 - 22:00",
                "Sat 11:00 - 22:00",
                "Sun 11:00 - 21:30"
                ],
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "35.0075162",
                "longitude": "-85.26283180000001"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "4423-624-4345",
                "contactType": "Customer Service"
             },
                "menu":"http://www.amigorestaurantonline.com/mexican-food-menu",
                "department": [
                    {
                      "name": "Amigo Mexican Restaurant in East Ridge",
                      "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "East Ridge",
                        "addressRegion": "TN",
                        "streetAddress": "3805 Ringgold Rd",
                        "postalCode": "37412",
                        "addressCountry": "United States"
                      },
                    "telephone": "423-624-4345"
                  },
                  {
                    "name": "Amigo Mexican Restaurant in Brainerd",
                    "address": {
                      "@type": "PostalAddress",
                      "addressLocality": "Brainerd",
                      "addressRegion": "TN",
                      "streetAddress": "5794 Brainerd Road",
                      "postalCode": "37411",
                      "addressCountry": "United States"
                    },
                    "telephone": "423-499-5435"
                  },
                  {
                    "name": "Amigo Mexican Restaurant in Hixson",
                    "address": {
                      "@type": "PostalAddress",
                      "addressLocality": "Hixson",
                      "addressRegion": "TN",
                      "streetAddress": "5450 Highway 153",
                      "postalCode": "37343",
                      "addressCountry": "United States"
                    },
                    "telephone": "423-875-8049"
                  },
                  {
                    "name": "Amigo Mexican Restaurant in Johnson City",
                    "address": {
                      "@type": "PostalAddress",
                      "addressLocality": "Johnson City W Market St",
                      "addressRegion": "TN",
                      "streetAddress": "1705 W. Market St",
                      "postalCode": "37604",
                      "addressCountry": "United States"
                    },
                    "telephone": "423-957-0252"
                  },
                  {
                    "name": "Amigo Mexican Restaurant in Johnson City",
                    "address": {
                      "@type": "PostalAddress",
                      "addressLocality": "Johnson City",
                      "addressRegion": "TN",
                      "streetAddress": "3211 Peoples St, Suite 70",
                      "postalCode": "37604",
                      "addressCountry": "United States"
                    },
                    "telephone": "423-952-0551"
                  },
                  {
                    "name": "Amigo Mexican Restaurant in Elizabethton",
                    "address": {
                      "@type": "PostalAddress",
                      "addressLocality": "Elizabethton",
                      "addressRegion": "TN",
                      "streetAddress": "623 W Elk Ave",
                      "postalCode": "37643",
                      "addressCountry": "United States"
                  },
                    "telephone": "423-543-1711"
                  },
                  {
                    "name": "Amigo Mexican Restaurant in Jonesborough",
                    "address": {
                      "@type": "PostalAddress",
                      "addressLocality": "Jonesborough",
                      "addressRegion": "TN",
                      "streetAddress": "125 E Jackson Blvd",
                      "postalCode": "37659",
                      "addressCountry": "United States"
                  },
                  "telephone": "423-788-2804"
                }
              ]
            }
            `}
          </script>
        </Helmet>
        <Header />
        <main id="main" className="main-content">
          {children}
        </main>
        <Footer />
        {/*{withMapPopUp() && <LocationMapPopUp />}*/}
      </SelectedLocationContextProvider>
    </>
  );
}
