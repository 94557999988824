// Display WYSIWYG Content

import React from "react";

const Content = ({ className, children, ...other }) => {
  return (
    <div
      className={`content ${className ? className : ""}`}
      dangerouslySetInnerHTML={{ __html: children }}
      {...other}
    />
  );
};

export default Content;
